import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Card, Typography } from "@mui/material";
import Vide from '../prothese/assets/vide.svg';
export default function EmptyProthese() {
    return (_jsxs(Card, { sx: {
            marginTop: '15px',
            width: '100%',
            overflow: 'auto',
            height: '600px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            gap: '20px',
        }, children: [_jsx(Typography, { variant: "h6", color: 'text.secondary', children: "s\u00E9lectionnez une dent pour commencer \u00E0 cr\u00E9er une proth\u00E8se" }), _jsx("img", { src: Vide, alt: "vide_section", width: '50%' })] }));
}
